/**
 * CardVideoText
 */

import React from 'react';
import CardVideo from 'Components/CardVideo';
import Button from 'Components/Button';
import classNames from 'classnames';
import s from './CardVideoText.module.scss';

const CardVideoText = ({
    youtubeIdOrUrl,
    vimeoIdOrUrl,
    title = '',
    text = '',
    link,
    modifiers = [],
    image,
    reverse,
    choice,
}) => {
    const hasContent = Boolean(title || text);

    const wide = choice && choice === 'wide'; // "Fullbredd"
    const increaseVideoSize = choice && (
        choice === 'increaseVideoSize' || choice === 'increaseImageSize'
    ); // "Bredare bredd"

    const imageSizes = (wide || !hasContent) ? [
        '(min-width: 1200px) 1145px',
        '100vw'
    ] : (
        increaseVideoSize ? [
            '(min-width: 1200px) 744px',
            '(min-width: 960px) 65vw',
            '100vw',
        ] : [
            '(min-width: 1200px) 573px',
            '(min-width: 960px) 50vw',
            '100vw',
        ]
    );

    const classes = classNames(
        s['CardVideoText'],
        {[s['CardVideoText--Reverse']]: reverse },
        {[s['CardVideoText--IncreaseVideoSize']]: increaseVideoSize },
        {[s['CardVideoText--Horizontal']]: !wide },
        {[s['CardVideoText--Wide']]: wide || !hasContent },
        {[s['CardVideoText--Red']]: modifiers.includes('Red') },
    );

    return (
        <article className={classes}>
            {hasContent && wide && (
                <div className={s['CardVideoText__Header']}>
                    {title && <h2 className={s['CardVideoText__Title']}>{title}</h2>}
                </div>
            )}
            <div className={s['CardVideoText__Video']}>
                {(youtubeIdOrUrl || vimeoIdOrUrl) && (
                    <CardVideo
                        vimeoIdOrUrl={vimeoIdOrUrl}
                        youtubeIdOrUrl={youtubeIdOrUrl}
                        image={image}
                        imageSizes={imageSizes}
                    />
                )}
            </div>

            {hasContent && !wide && (
                <div className={s['CardVideoText__Content']}>
                    {!!title && (
                        <h3 className={s['CardVideoText__Title']}>{title}</h3>
                    )}
                    {!!text && <p className={s['CardVideoText__Text']}>{text}</p>}
                    {link && link.url && link.text && (
                        <Button href={link.url} text={link.text} />
                    )}
                </div>
            )}
        </article>
    );
};

export default CardVideoText;
