/**
 * CardVideo
 */

import React, {useState} from 'react';
import classNames from 'classnames';
import EmbedMessage from 'Components/EmbedMessage';
import Image from 'Components/Image';
import s from './CardVideo.module.scss';

const CardVideo = ({youtubeIdOrUrl, vimeoIdOrUrl, image, imageSizes = ['100vw']}) => {

    const [showVideo, setShowVideo] = useState(false);
    const [showMsg, setShowMsg] = useState(youtubeIdOrUrl !== '');

    const getYouTubeVideoIdFromUrl = (videoIdOrUrl) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = videoIdOrUrl.match(regExp);
        return match && match[7].length === 11 ? match[7] : videoIdOrUrl;
    };

    // Match 7 exists, this is a branded video
    const getVimeoVideoIdFromUrl = (videoIdOrUrl) => {
        const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)(\/([0-9a-z]+))?/;
        const match = videoIdOrUrl.match(regExp);
        return match && typeof(match) === 'object' ? (
            match[7] !== undefined ? `${match[5]}/${match[7]}` : match[5]
        ) : videoIdOrUrl;
    };

    let iframeSrc = '';
    const bgImage = image && image.src ? image : null;
    let videoImage = null;
    let url = youtubeIdOrUrl ? youtubeIdOrUrl : vimeoIdOrUrl;

    if (youtubeIdOrUrl) {
        const videoId = getYouTubeVideoIdFromUrl(youtubeIdOrUrl);
        iframeSrc = `https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1`;
        if(!bgImage) {
            videoImage = {
                src: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
            };
        }
        if(videoId === youtubeIdOrUrl) {
            url = `https://www.youtube.com/watch?v=${videoId}`;
        }
    } else if (vimeoIdOrUrl) {
        const vimeo = getVimeoVideoIdFromUrl(vimeoIdOrUrl).split('/');
        const videoId = vimeo[0];
        const brandedId = vimeo[1];
        const endSrc = brandedId !== undefined ? `&h=${brandedId}` : '';
        iframeSrc = `https://player.vimeo.com/video/${videoId}?dnt=1&autoplay=${
            bgImage ? '1' : '0'
        }${endSrc}`;
        if(videoId === vimeoIdOrUrl) {
            url = `https://vimeo.com/${videoId}`;
        }
    }

    const hasImage = !!bgImage || !!videoImage;

    const classes = classNames(
        s['CardVideo'],
        {[s['CardVideo--ShowMsg']]: showMsg},
    );

    return (
        <article className={classes}>
            {!showVideo && hasImage && (
                <button
                    type="button"
                    onClick={() => setShowVideo(true)}
                    className={s['CardVideo__Button']}
                    aria-label="Spela video"
                >{videoImage ? <img
                    className={s['CardVideo__BackgroundImage']}
                    alt=""
                    {...videoImage}
                /> : <Image
                    className={s['CardVideo__BackgroundImage']}
                    {...bgImage}
                    sizes={imageSizes}
                />}</button>
            )}
            {showMsg &&
                <div className={s['CardVideo__Message']}>
                    <EmbedMessage
                        src={url}
                        hideMessage={() => setShowMsg(false)}
                        showEmbed={() => setShowVideo(true)}
                        category="targeting"
                    />
                </div>
            }
            {!showMsg && (showVideo || !hasImage) && (
                <div className={s['CardVideo__Video']}>
                    <iframe
                        title="Video"
                        src={iframeSrc}
                        frameBorder="0"
                        allow="autoplay"
                        allowFullScreen
                    />
                </div>
            )}
        </article>
    );
};

export default CardVideo;
